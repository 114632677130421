import { ContentTypeView, NextUrl, Sizes } from 'utility/utility'
import { Featured } from 'components/shared/Featured/Featured'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { PolymerDemandFeaturedFragment } from 'generated/graphql'
import { PolymerDemandLink } from 'content-types/PolymerDemand/PolymerDemand.Link/PolymerDemand.Link'

export const PolymerDemandFeatured: ContentTypeView<NextUrl<PolymerDemandFeaturedFragment>> =
    ({
        picture,
        ...polymerDemand
    }) =>
        <Featured
            tags={
                <MetadataTag>Data</MetadataTag>
            }
            link={
                <PolymerDemandLink {...polymerDemand}>
                    {
                        picture === undefined ? <></> :
                            <ComponentImagePicture
                                {...picture}
                                sizes={Sizes.Featured}
                            />
                    }
                    { polymerDemand.title }
                </PolymerDemandLink>
            }
        />
