'use client'

import type { FC, Dispatch, SetStateAction } from 'react'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { InstantSearch, InfiniteHits } from 'react-instantsearch-hooks-web'

import { type SiteContext } from 'context/SiteContext'
import { type PageContext } from 'context/PageContext'
import { DisplayState } from 'content-types/Header/HeaderClient'
import { SearchBox } from 'components/search/SearchBox/SearchBox'
import { normaliseDate } from 'utility/utility'

import type {
    ActivityFeaturedFragment,
    ArticleFeaturedFragment,
    DatabaseFeaturedFragment,
    EventFeaturedFragment,
    HomeFeaturedFragment,
    InsightReportFeaturedFragment,
    MagazineFeaturedFragment,
    MarketReportFeaturedFragment,
    PageFeaturedFragment,
    PolymerDemandFeaturedFragment,
    PortfolioFeaturedFragment,
    TopicFeaturedFragment
} from 'generated/graphql'

type SearchHitData =
    | ActivityFeaturedFragment
    | ArticleFeaturedFragment
    | DatabaseFeaturedFragment
    | EventFeaturedFragment
    | HomeFeaturedFragment
    | InsightReportFeaturedFragment
    | MagazineFeaturedFragment
    | MarketReportFeaturedFragment
    | PageFeaturedFragment
    | PolymerDemandFeaturedFragment
    | PortfolioFeaturedFragment
    | TopicFeaturedFragment

import { ActivitySearchResult } from 'content-types/Activity/Activity.SearchResult/Activity.SearchResult'
import { ArticleFeatured } from 'content-types/Article/Article.Featured/Article.Featured'
import { DatabaseFeatured } from 'content-types/Database/Database.Featured/Database.Featured'
import { EventFeatured } from 'content-types/Event/Event.Featured/Event.Featured'
import { HomeFeatured } from 'content-types/Home/Home.Featured/Home.Featured'
import { InsightReportFeatured } from 'content-types/InsightReport/InsightReport.Featured/InsightReport.Featured'
import { MagazineFeatured } from 'content-types/Magazine/Magazine.Featured/Magazine.Featured'
import { MarketReportFeatured } from 'content-types/MarketReport/MarketReport.Featured/MarketReport.Featured'
import { PageFeatured } from 'content-types/Page/Page.Featured/Page.Featured'
import { PolymerDemandFeatured } from 'content-types/PolymerDemand/PolymerDemand.Featured/PolymerDemand.Featured'
import { PortfolioSearchResult } from 'content-types/Portfolio/Portfolio.SearchResult/Portfolio.SearchResult'
import { TopicFeatured } from 'content-types/Topic/Topic.Featured/Topic.Featured'

import styles from './Search.module.scss'

export const Search: FC<{
    siteContext: SiteContext
    pageContext: PageContext
    setDisplayState: Dispatch<SetStateAction<DisplayState>>
}> = ({
    siteContext,
    pageContext,
    setDisplayState
}) => {

    const searchClient = instantMeiliSearch(
        siteContext.meiliHost,
        siteContext.meiliApiKey,
        { placeholderSearch: false }
    )

    const SearchHit: FC<{hit: SearchHitData}> =
        ({hit}) => {
            switch ( hit.__typename ) {
                case 'Activity':
                    return <ActivitySearchResult {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Article':
                    return <ArticleFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Database':
                    return <DatabaseFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Event': {
                    return hit.endDate !== null && normaliseDate(new Date(hit.endDate)) < normaliseDate(new Date()) ? <></> :
                        <EventFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                }
                case 'Home':
                    return <HomeFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'InsightReport':
                    return <InsightReportFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Magazine':
                    return <MagazineFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'MarketReport':
                    return <MarketReportFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Page':
                    return <PageFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'PolymerDemand':
                    return <PolymerDemandFeatured {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Portfolio':
                    return <PortfolioSearchResult {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                case 'Topic':
                    return <TopicFeatured  {...hit} siteContext={siteContext} pageContext={pageContext} articleContext={{}} />
                default:
                    return <><strong>{hit.__typename}:</strong> {hit.title}</>
            }
        }

    return (
        <InstantSearch
            indexName='content'
            searchClient={searchClient}
        >
            <SearchBox
                setDisplayState={setDisplayState}
            />
            <InfiniteHits
                className={styles.Hits}
                showPrevious={false}
                hitComponent={SearchHit}
                onClick={ ({target}): void => {
                    if ( target instanceof HTMLElement && target.closest('a') !== null ) {
                        setDisplayState(DisplayState.Minimal)
                    }
                }}
            />
        </InstantSearch>
    )

}
